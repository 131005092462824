export const service = [
  {
    id: "1",
    title: "Web Developer",
    content:
      " Lorem ipsum dolor sit amet consectetur adipisicing elit. Ipsum eos autem dolores, natus at praesentium?",
    link: "Hello",
    iconClass: "fa-solid fa-code",
  },
  {
    id: "2",
    title: "App Developer",
    content:
      " Lorem ipsum dolor sit amet consectetur adipisicing elit. Ipsum eos autem dolores, natus at praesentium?",
    link: "Hello",
    iconClass: "fa-brands fa-app-store",
  },

  {
    id: "3",
    title: "UI/UX Design",
    content:
      " Lorem ipsum dolor sit amet consectetur adipisicing elit. Ipsum eos autem dolores, natus at praesentium?",
    link: "Hello",
    iconClass: "fa-solid fa-crop",
  },
];
